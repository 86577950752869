import "chartkick/highcharts";
import spinner_button from "images/load.gif";
import spinner from "images/spinner.gif";
import _ from "lodash";
import * as moment from "moment";
import React, { Component } from "react";
import { ColumnChart } from "react-chartkick";
import FadeIn from "react-fade-in";
import NumberFormat from "react-number-format";
import { getBillHistorical, getData, getPdf } from "../actions/index";

export default class BillsHistorical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      data_chart: [],
      current_debt: {},
      bills: [],
      error: "",
      loading: true,
    };
    this.renderState = this.renderState.bind(this);
    this.pdfClickHandler = this.pdfClickHandler.bind(this);
  }

  componentDidMount() {
    setTimeout(
      function () {
        getData((response) => {
          this.setState({ loading: false });
          if (response.status === 200) {
            this.setState({
              data_chart: response.data.data_chart,
              data_colors: response.data.data_colors,
              current_debt: response.data.current_debt,
            });
          } else {
            this.setState({
              error: "Ha ocurrido un error al cargar los datos.",
            });
          }
        });
        getBillHistorical((response) => {
          this.setState({ loading: false });
          if (response.status === 200) {
            this.setState({ bills: response.data });
          } else {
            this.setState({
              error: "Ha ocurrido un error al cargar los datos.",
            });
          }
        });
      }.bind(this),
      1500
    );
  }

  renderState(state) {
    if (state === "pending" || state === "payed_previous_balance") {
      return <td className="estado-pendiente">Pendiente</td>;
    } else if (state === "payed" || state === "payed_total_amount") {
      return <td className="estado-cancelada">Cancelada</td>;
    }
  }

  pdfClickHandler(event, document_number) {
    event.preventDefault();
    let newBills = [...this.state.bills];
    let bill = _.find(newBills, { document_number: document_number });
    let billIndex = _.findIndex(newBills, { document_number: document_number });
    if (bill.loading_button) {
      return;
    }
    bill.loading_button = true;
    newBills[billIndex] = bill;
    this.setState({ bills: newBills });
    getPdf(this.serializePdfClick(bill), (response) => {
      if (response.status === 200) {
        bill.loading_button = false;
        newBills[billIndex] = bill;
        this.setState({ bills: newBills });
        if (response.data.pdf !== "") {
          bill.pdf = response.data.pdf;
          newBills[billIndex] = bill;
          this.setState({ bills: newBills });
          window.open(response.data.pdf);
        }
      }
    });
  }

  serializePdfClick(bill) {
    let { current_user, client } = this.state;
    var formData = new FormData();
    formData.append("user_rut", current_user.rut);
    formData.append("user_token", current_user.authentication_token);
    formData.append("client_number", client.client_number);
    formData.append("document_number", bill.document_number);

    return formData;
  }

  render() {
    let { data_chart, data_colors, bills, error, loading } = this.state;
    const current_day = moment().format("dddd DD");
    const current_month = moment().locale("es").format("MMMM / YYYY");
    if (loading) {
      return (
        <div className="col-md-9">
          <div className="container-white-box">
            <FadeIn>
              <div style={{ paddingBottom: "80px" }}>
                <img src={spinner} className="img-center" />
              </div>
            </FadeIn>
          </div>
        </div>
      );
    } else {
      if (error.trim() !== "") {
        return (
          <div className="col-md-9">
            <div className="container-white-box">
              <FadeIn>
                <p>{error}</p>
              </FadeIn>
            </div>
          </div>
        );
      }
      return (
        <div className="col-md-9">
          <div className="container-white-box">
            <div className="row">
              <div className="col-md-12">
                <FadeIn>
                  <div className="title-btn-flex">
                    <div className="titulos-seccion">
                      <h1>Historial de Facturación</h1>
                      <h2>Consumo en los últimos 13 meses</h2>
                    </div>
                    <div className="fecha-actual">
                      <h4>{current_day}</h4>
                      <p>{current_month}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-12"
                      styles={{ paddingTop: "15px", paddingBottom: "15px" }}
                    >
                      <ColumnChart
                        data={data_chart}
                        label="m&#179;"
                        colors={data_colors}
                        ytitle="m&#179;s"
                        xtitle="Mes"
                        library={{
                          xAxis: {
                            labels: {
                              formatter: function () {
                                return moment(this.value)
                                  .locale("es")
                                  .format("MMMM");
                              },
                            },
                            title: { style: { fontSize: "10px" } },
                          },
                          yAxis: { title: { style: { fontSize: "10px" } } },
                          plotOptions: {
                            series: {
                              colorByPoint: true,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="bloque-gris">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="red">
                              Advertencia: el despliegue de los documentos
                              podría tardar más de lo habitual.
                            </p>
                            <table className="tabla-consumo-historial">
                              <thead>
                                <tr>
                                  <td className="table-bold">Periodo</td>
                                  <td className="table-bold hidden-xs">
                                    Consumo M3
                                  </td>
                                  <td className="table-bold">Total Boleta</td>
                                  <td className="table-bold">Nº Boleta</td>
                                  {/*<td className="table-bold">Estado</td>*/}
                                </tr>
                              </thead>
                              <tbody>
                                {_.map(bills, (bill, index) => (
                                  <tr
                                    className="tableblock"
                                    key={bill.document_number}
                                  >
                                    <td>{bill.reading_date}</td>
                                    <td className="hidden-xs">
                                      {bill.consumption_m3}
                                    </td>
                                    <td>
                                      <NumberFormat
                                        className="amount"
                                        value={bill.total_paid}
                                        displayType={"text"}
                                        thousandSeparator={"."}
                                        prefix={"$"}
                                        decimalSeparator={","}
                                      />
                                    </td>
                                    <td>
                                      {bill.pdf !== null &&
                                      bill.pdf !== "" &&
                                      bill.pdf !== undefined ? (
                                        <a href={bill.pdf} target="blank">
                                          {bill.document_number}
                                        </a>
                                      ) : (
                                        <a
                                          href="#"
                                          target="blank"
                                          onClick={(e) => {
                                            this.pdfClickHandler(
                                              e,
                                              bill.document_number
                                            );
                                          }}
                                          disabled={bill.loading_button}
                                        >
                                          {bill.loading_button ? (
                                            <img src={spinner_button} />
                                          ) : (
                                            bill.document_number
                                          )}
                                        </a>
                                      )}
                                    </td>
                                    {/*this.renderState(bill.aasm_state)*/}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeIn>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
